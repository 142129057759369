import React from "react"
import parseDate from "../../../utils/parseDate"

import * as s from "./BlogPost.module.css"
import BlogPostContent from "./BlogPostContent"
import BlogPostMeta from "./BlogPostMeta"
import BlogPostTitle from "./BlogPostTitle"

// TODO: Currently a copy of BlogPost. Work on this

const BlogPostExcerpt = ({ post }) => {
  const date = parseDate(post.frontmatter.date)
  const categories = post.fields.categories
  const tags = post.fields.tags

  return <article className={s.blogPost} lang={post.frontmatter.lang}>
    <BlogPostTitle title={post.frontmatter.title} path={post.fields.slug} />
    <BlogPostMeta
      date={date}
      categories={categories}
      tags={tags}
    />
    <BlogPostContent html={post.excerpt} />
  </article>
}

export default BlogPostExcerpt
