import React from "react"
import { graphql } from "gatsby"
import DefaultLayout from '../layouts/DefaultLayout'
import BlogPostExcerpt from "../components/blogPost/BlogPostExcerpt"
import * as s from "../styles/Index.module.css"

const index = ({ data }) => (
  <DefaultLayout useShortTitle={false}>
    <h2 className={s.pageSection}>Recent posts</h2>
    {data.allMarkdownRemark.edges.map(({node: post}) => (
      <BlogPostExcerpt post={post} key={post.id} />
    ))}
  </DefaultLayout>
)

export default index

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "post" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ){
      edges {
        node {
          id
          frontmatter {
            title
            date
            lang
          }
          fields {
            slug
            categories { id, name, url }
            tags { id, name, url }
          }
          excerpt
        }
      }
    }
  }
`
